import { ReactNode } from "react";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { getAccessTokenWithFacebook, getAccessTokenWithGoogle, getAccessTokenWithTwitter, init as initFirebase } from "../common/firebase";
import { get, post } from "../common/gateway";
import IUser from "../common/models/user";
import { IState } from "./state";
import { AUTHENTICATED, INITIALIZING, OVERLAY_MODAL, OVERLAY_NOTIF, USER_LOADED } from "./types";

// **** //
// Init //
// **** //

export const initialize = () => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => IState,
  ) => {
    initFirebase();

    const state = getState();
    if (state.authenticated) {
      await dispatch(getMe());
    }

    dispatch({
      type: INITIALIZING,
      payload: false,
    });
  };
};

// **** //
// Auth //
// **** //

const signinWithProvider = (accessTokenGetter: () => Promise<string>) => (username?: string) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => IState,
  ) => {
    const state = getState();
    const accessToken = await accessTokenGetter();
    if (!!accessToken) {
      try {
        await post(
          `sessions`,
          { username, accessToken },
          undefined,
          false,
          {},
          state.csrfToken,
        );

        dispatch(postAuth());

        return true;
      } catch (e) { }

      return false;
    }
  };
};

export const signinWithFacebook = signinWithProvider(async () => await getAccessTokenWithFacebook());
export const signinWithTwitter = signinWithProvider(async () => await getAccessTokenWithTwitter());
export const signinWithGoogle = signinWithProvider(async () => await getAccessTokenWithGoogle());

export const signout = () => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => IState
  ) => {
    const state = getState();

    try {
      await get(
        `sessions`,
        undefined,
        true,
        {},
        state.csrfToken
      );

      dispatch({
        type: AUTHENTICATED,
        payload: false,
      });

      return true;
    } catch (e) { }

    return false;
  };
};

export const postAuth = () => {
  return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch({
      type: INITIALIZING,
      payload: true,
    });

    dispatch({
      type: AUTHENTICATED,
      payload: true,
    });

    dispatch({
      type: OVERLAY_NOTIF,
      payload: null,
    });

    dispatch({
      type: OVERLAY_MODAL,
      payload: null,
    });

    dispatch(initialize());
  };
};

// ** //
// Me //
// ** //

export const getMe = () => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => IState
  ) => {
    const state = getState();

    try {
      const response = await get(
        "users/me",
        undefined,
        true,
        {},
        state.csrfToken
      );

      if (!!response) {
        const user = response as IUser;

        dispatch({
          type: USER_LOADED,
          payload: user,
        });

        return user;
      }
    } catch (e) { }

    return undefined;
  };
};

export const getUploadSignature = (params: any) => {
  return async (
    _: ThunkDispatch<any, any, AnyAction>,
    getState: () => IState
  ) => {
    const state = getState();

    try {
      const response = await get(
        "users/me/uploads/signature",
        params,
        true,
        {},
        state.csrfToken
      );

      if (!!response) {
        return response.signature;
      }
    } catch (e) { }

    return undefined;
  };
};

export const updateMe = (me: IUser) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => IState
  ) => {
    const state = getState();

    try {
      const response = await post(
        "users/me",
        me,
        undefined,
        true,
        {},
        state.csrfToken
      );

      if (!!response) {
        const user = response as IUser;

        dispatch({
          type: USER_LOADED,
          payload: user,
        });

        return user;
      }
    } catch (e) { }

    return undefined;
  };
};

// ***** //
// Users //
// ***** //

export const getUsernameClaimed = (username: string) => {
  return async (
    _: ThunkDispatch<any, any, AnyAction>,
    getState: () => IState
  ) => {
    const state = getState();

    try {
      const response = await get(
        `users/${username}/claimed`,
        undefined,
        true,
        {},
        state.csrfToken
      );

      if (!!response) {
        return response.claimed;
      }
    } catch (e) { }

    return false;
  };
};

// ******** //
// Overlays //
// ******** //

export const overlayNotif = (body: ReactNode = null) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
  ) => {
    dispatch({
      type: OVERLAY_NOTIF,
      payload: body,
    });
  };
};

export const overlayModal = (body: ReactNode = null) => {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
  ) => {
    dispatch({
      type: OVERLAY_MODAL,
      payload: body,
    });
  };
};