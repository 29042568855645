import { useEffect, useState } from "react";
import { isServer, getDeviceBreakpoint } from "./utils";
import throttle from "lodash.throttle";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../redux/state";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

export const useBreakpoint = () => {
  if (isServer()) {
    return "lg";
  } else {
    const [breakpoint, setBreakpoint] = useState(() =>
      getDeviceBreakpoint(window.innerWidth)
    );

    useEffect(() => {
      const calcInnerWidth = throttle(function () {
        setBreakpoint(getDeviceBreakpoint(window.innerWidth));
      }, 200);

      window.addEventListener("resize", calcInnerWidth);
      return () => window.removeEventListener("resize", calcInnerWidth);
    }, []);

    return breakpoint;
  }
};

export const useThunkDispatch = () =>
  useDispatch<ThunkDispatch<any, any, AnyAction>>();

export const useInitializing = () =>
  useSelector((state: IState) => state.initializing);

export const useAuthenticated = () =>
  useSelector((state: IState) => state.authenticated);

export const useMe = () => useSelector((state: IState) => state.user);

export const useOverlayNotif = () =>
  useSelector((state: IState) => state.overlayNotif);

export const useOverlayModal = () =>
  useSelector((state: IState) => state.overlayModal);
