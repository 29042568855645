import axios from "axios";

import { camelCase, snakeCase, snakeCaseString } from "./utils";

export const csrfHeader = "X-CSRF-Token";

export const generateHeaders = (
  auth?: boolean,
  baseHeaders?: any,
  contentType?: string,
  csrfToken?: string
) => {
  const headers = !!baseHeaders ? baseHeaders : {};
  headers["Content-Type"] = contentType || "application/json";

  if (auth) {
    if (!!csrfToken) {
      headers[csrfHeader] = csrfToken;
    }
  }

  return headers;
};

export const generateUrl = (path: string, auth?: boolean, query?: any) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/${
    auth ? "private" : "public"
  }/${path}`;

  if (!!query && Object.keys(query).length > 0) {
    return `${url}?${Object.keys(query).reduce(
      (accumulator: string, currentValue: string, currentIndex: number) =>
        accumulator +
        ((currentIndex > 0 ? "&" : "") +
          encodeURIComponent(snakeCaseString(currentValue)) +
          "=" +
          encodeURIComponent(query[currentValue])),
      ""
    )}`;
  } else {
    return url;
  }
};

export const get = async (
  path: string,
  query: any = undefined,
  auth: boolean = true,
  headers: any = {},
  csrfToken: string = undefined
) => {
  const response = await axios.get(generateUrl(path, auth, query), {
    withCredentials: true,
    headers: generateHeaders(auth, headers, undefined, csrfToken),
  });

  if (!!response.data) {
    return camelCase(response.data) as any;
  }
};

export const post = async (
  path: string,
  data: any = undefined,
  query: any = undefined,
  auth: boolean = true,
  headers: any = {},
  csrfToken: string = undefined
) => {
  const response = await axios.post(
    generateUrl(path, auth, query),
    !!data ? snakeCase(data) : undefined,
    {
      withCredentials: true,
      headers: generateHeaders(auth, headers, undefined, csrfToken),
    }
  );

  if (!!response.data) {
    return camelCase(response.data) as any;
  }
};

export const put = async (
  path: string,
  data: any = undefined,
  query: any = undefined,
  auth: boolean = true,
  headers: any = {},
  csrfToken: string = undefined
) => {
  const response = await axios.put(
    generateUrl(path, auth, query),
    !!data ? snakeCase(data) : undefined,
    {
      withCredentials: true,
      headers: generateHeaders(auth, headers, undefined, csrfToken),
    }
  );

  if (!!response.data) {
    return camelCase(response.data) as any;
  }
};

export const del = async (
  path: string,
  data: any = undefined,
  query: any = undefined,
  auth: boolean = true,
  headers: any = {},
  csrfToken: string = undefined
) => {
  const response = await axios.delete(generateUrl(path, auth, query), {
    withCredentials: true,
    headers: generateHeaders(auth, headers, undefined, csrfToken),
    data: !!data ? snakeCase(data) : undefined,
  });

  if (!!response.data) {
    return camelCase(response.data) as any;
  }
};
