import firebase from "firebase/app";
import "firebase/auth";

// Setup Firebase config.
var firebaseConfig = {
  apiKey: "AIzaSyDwRpkejHHhNHd-pEsBw5laF9yniy5ed90",
  authDomain: "paymewith-cde75.firebaseapp.com",
  projectId: "paymewith-cde75",
  storageBucket: "paymewith-cde75.appspot.com",
  messagingSenderId: "80017078278",
  appId: "1:80017078278:web:f99d42edd8d7bdf85bab87",
};

var initialized = false;

const init = () => {
  if (!initialized) {
    firebase.initializeApp(firebaseConfig);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
    firebase.auth().useDeviceLanguage();
  }

  initialized = true;
};

const getAccessTokenWithFacebook = async () => {
  init();
  const provider = new firebase.auth.FacebookAuthProvider();
  return getAccessTokenWithProvider(provider);
};

const getAccessTokenWithTwitter = async () => {
  init();
  const provider = new firebase.auth.TwitterAuthProvider();
  return getAccessTokenWithProvider(provider);
};

const getAccessTokenWithGoogle = async () => {
  init();
  const provider = new firebase.auth.GoogleAuthProvider();
  return getAccessTokenWithProvider(provider);
};

const getAccessTokenWithProvider = async (provider) => {
  try {
    const userCredential = await firebase.auth().signInWithPopup(provider);
    if (!!userCredential.user) {
      return await userCredential.user.getIdToken();
    }
  } catch (e) {
    console.log(e);
  }

  await firebase.auth().signOut();
  return undefined;
};

export {
  init,
  getAccessTokenWithFacebook,
  getAccessTokenWithTwitter,
  getAccessTokenWithGoogle,
};
